<template>
  <q-card style="width: 800px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          编辑
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <!-- <q-input
                        label="上级机构" 
                       dense
                       outlined
                       class="col-2"
                        v-model="TgyBaseHospbasicinfoEntity.PARENTID "
                    />
                    <q-input
                        label="医院编号" 
                       dense
                       outlined
                       class="col-2"
                        v-model="TgyBaseHospbasicinfoEntity.HOSPNO "
                    /> -->
        <q-input
          label="医院名称"
          dense
          outlined
          class="col-5"
          v-model="TgyBaseHospbasicinfoEntity.HospName"
        />
        <!-- <q-input
                        label="医院级别 1:三级医院 2:二级医院 3:三级医院 4:社区门诊" 
                       dense
                       outlined
                       class="col-2"
                        v-model="TgyBaseHospbasicinfoEntity.HOSPLEVEL "
                    /> -->
        <q-input
          label="医保编码--必填"
          dense
          outlined
          class="col-3"
          v-model="TgyBaseHospbasicinfoEntity.HospMICode"
        />
        <q-input
          label="机构区划"
          dense
          outlined
          class="col-3"
          v-model="TgyBaseHospbasicinfoEntity.HospAreaCode"
        />

        <!-- <q-input
                        label="" 
                       dense
                       outlined
                       class="col-2"
                        v-model="TgyBaseHospbasicinfoEntity.COMMUNITYMICOD "
                    /> -->
        <q-input
          label="机构外网IP(不要/)"
          dense
          outlined
          class="col-11"
          v-model="TgyBaseHospbasicinfoEntity.INTERNETIP"
        />
        <q-input
          label="银联商务三方分配AppID"
          dense
          outlined
          class="col-5"
          v-model="TgyBaseHospbasicinfoEntity.APPID"
        />
        <q-input
          label="银联商务三方分配AppKey"
          dense
          outlined
          class="col-6"
          v-model="TgyBaseHospbasicinfoEntity.APPKEY"
        />
        <q-input
          label="银联商务三方分配-商户号"
          dense
          outlined
          class="col-4"
          v-model="TgyBaseHospbasicinfoEntity.MID"
        />
        <q-input
          label="银联商务三方分配-来源编号"
          dense
          outlined
          class="col-4"
          v-model="TgyBaseHospbasicinfoEntity.MSGSRCID"
        />
        <q-input
          label="银联商务三方分配-终端号"
          dense
          outlined
          class="col-3"
          v-model="TgyBaseHospbasicinfoEntity.TID"
        />
        <!-- <q-input
                        label="银联商务-异步回调地址" 
                       dense
                       outlined
                       class="col-11"
                        v-model="TgyBaseHospbasicinfoEntity.NOTIFYURL "
                    />
                    <q-input
                        label="银联商务-同步回调地址" 
                       dense
                       outlined
                       class="col-11"
                        v-model="TgyBaseHospbasicinfoEntity.RETURNURL "
                    /> -->
        <q-input
          label="三方分配-MD5Key"
          dense
          outlined
          class="col-8"
          v-model="TgyBaseHospbasicinfoEntity.MD5KEY"
        />

        <q-input
          label="二维码失效时间：秒"
          dense
          outlined
          class="col-3"
          v-model="TgyBaseHospbasicinfoEntity.EXPIRETIME"
        />
        <!-- <q-input
                        label="签约大病单次服务额度" 
                       dense
                       outlined
                       class="col-2"
                        v-model="TgyBaseHospbasicinfoEntity.SIGNDBSINGLEAMT "
                    /> -->
        <q-input
          label="微信小程序ID"
          dense
          outlined
          class="col-5"
          v-model="TgyBaseHospbasicinfoEntity.WX_APPID"
        />
        <q-input
          label="微信小程序密钥AppSecret"
          dense
          outlined
          class="col-6"
          v-model="TgyBaseHospbasicinfoEntity.WX_APPSECRET"
        />
        <q-input
          label="微信商户号"
          dense
          outlined
          class="col-4"
          v-model="TgyBaseHospbasicinfoEntity.WX_MERCHANTID"
        />
        <q-input
          label="微信商户 v3 API 密钥"
          dense
          outlined
          class="col-7"
          v-model="TgyBaseHospbasicinfoEntity.WX_MERCHANTV3SECRET"
        />
        <q-input
          label="微信商户证书序列号--可通过在线查看器查看"
          dense
          outlined
          class="col-11"
          v-model="TgyBaseHospbasicinfoEntity.WX_MERCHANTSERIALNUMBER"
        />
        <q-input
          label="-----BEGIN PRIVATE KEY-----微信商户证书私钥，即 `apiclient_key.pem` 文件内容-----END PRIVATE KEY-----"
          dense
          outlined
          class="col-11"
          v-model="TgyBaseHospbasicinfoEntity.WX_MERCHANTPRIVATEKEY"
        />
        <q-input
          label="微信支付回调地址"
          dense
          outlined
          class="col-11"
          v-model="TgyBaseHospbasicinfoEntity.WX_NOTIFYURL"
        />
        <q-input
          label="空中支付appId"
          dense
          outlined
          class="col-5"
          v-model="TgyBaseHospbasicinfoEntity.KZPay_AppID"
        />
        <q-input
          label="空中支付secret"
          dense
          outlined
          class="col-6"
          v-model="TgyBaseHospbasicinfoEntity.KZPay_Secret"
        />
        <q-input
          label="医保个账支付医保特约商户号(M开头)"
          dense
          outlined
          class="col-5"
          v-model="TgyBaseHospbasicinfoEntity.YB_MerchantID"
        />
        <q-input
          label="医保个账支付Client-Id"
          dense
          outlined
          class="col-6"
          v-model="TgyBaseHospbasicinfoEntity.YB_ClientID"
        />
        <q-input
          label="医保个账支付SignKey"
          dense
          outlined
          class="col-11"
          v-model="TgyBaseHospbasicinfoEntity.YB_Key"
        />
        <!-- <q-input
                        label="微信支付回调地址" 
                       dense
                       outlined
                       class="col-11"
                        v-model="TgyBaseHospbasicinfoEntity.WX_NOTIFYURL "
                    /> -->
        <q-input
          label="机构特色(打开小程序机构列表选择机构时显示)"
          dense
          outlined
          class="col-11"
          v-model="TgyBaseHospbasicinfoEntity.NOTE"
        />
        <q-input
          label="后端传输数据密钥"
          dense
          outlined
          class="col-10"
          v-model="TgyBaseHospbasicinfoEntity.PAYKEY"
        />
        <q-btn color="primary" @click="onPayKey" label="重置密钥" />
        <!-- <q-btn color="primary" label="获取机构Logo" /> -->
        <q-btn-toggle
          v-model="TgyBaseHospbasicinfoEntity.IsValid"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '不启用', value: false },
            { label: '启用', value: true },
          ]"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as BaseHospbasicinfo from '@/api/gy/base/baseHospbasicinfo.js';
export default {
  components: {},
  props: {},
  computed: {
    ...mapState("TgyBaseHospbasicinfo", [
      "TgyBaseHospbasicinfoShowDlg",
      "TgyBaseHospbasicinfoEntity",
      "TgyBaseHospbasicinfoList",
    ]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("TgyBaseHospbasicinfo", [
      "actUpdateTgyBaseHospbasicinfo",
      "actGetTgyBaseHospbasicinfoList",
    ]),
    onOK() {
      this.actUpdateTgyBaseHospbasicinfo({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.TgyBaseHospbasicinfoEntity,
      }).then((res) => {
        if (res.Code == 0) {
          // setTimeout(() => {
          //   this.$emit("onLoadData");
          // }, 1000);
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onPayKey(){
      BaseHospbasicinfo.GetPayKey({
        HospID:this.TgyBaseHospbasicinfoEntity.HospID
      }).then(res=>{
        if(res.Code==0){
          this.TgyBaseHospbasicinfoEntity.PAYKEY=res.Data;
        }else{
          this.msg.error(res.Message);
        }
      })
    }
  },
};
</script>
