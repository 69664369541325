/*------------------------------------------------
// File Name:TGY_BASE_HOSPBASICINFO.js
// File Description:TGY_BASE_HOSPBASICINFO API方法js接口
// Author:zxl
// Create Time:2022/12/06 16:37:58
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _baseHospbasicinfo: "/TGY_BASE_HOSPBASICINFO"
}

export default api


//-------------------创建对象-------------------
export function CreateTGY_BASE_HOSPBASICINFOEntity(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/CreateTGY_BASE_HOSPBASICINFOEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取机构列表--大后台用-------------------
export function GettGY_Base_HospBasicInfoListData(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/GettGY_Base_HospBasicInfoListData",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetTGY_BASE_HOSPBASICINFOList(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/GetTGY_BASE_HOSPBASICINFOList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetTGY_BASE_HOSPBASICINFOListByPage(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/GetTGY_BASE_HOSPBASICINFOListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetTGY_BASE_HOSPBASICINFOEntity(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/GetTGY_BASE_HOSPBASICINFOEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdateTGY_BASE_HOSPBASICINFOEntity(parameter) {
    return axios({
        url: api._baseHospbasicinfo+ "/UpdateTGY_BASE_HOSPBASICINFOEntity2",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeleteTGY_BASE_HOSPBASICINFOEntity(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/DeleteTGY_BASE_HOSPBASICINFOEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取机构Logo---------------
export function GetHospLogo(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/GetHospLogo",
        method: 'post',
        data: parameter
    })
}

//-------------------生成机构后端传输密钥---------------
export function GetPayKey(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/GetPayKey",
        method: 'post',
        data: parameter
    })
}

//-------------------修改机构启用/禁用---------------
export function UpateIsValid(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/UpateIsValid",
        method: 'post',
        data: parameter
    })
}

//-------------------生成机构二维码---------------
export function GetHospQRCode(parameter) {
    return axios({
        url: api._baseHospbasicinfo + "/GetHospQRCode",
        method: 'post',
        data: parameter
    })
}
